var iNoBounce = require("base/thirdParty/iNoBounce");
var menuDropdown = require("./menuDropdown");

var keyboardAccessibility = require("base/components/keyboardAccessibility");

var clearSelection = function (element) {
    $(element).closest(".dropdown").children(".dropdown-menu").children(".top-category")
        .detach();
    $(element).closest(".dropdown").children(".dropdown-menu").children(".link-text").detach();
    $(element).closest(".dropdown.show").children(".nav-link").attr("aria-expanded", "false");
    $(element).closest(".dropdown.show").children(".dropdown-menu").attr("aria-hidden", "true");
    $(element).closest(".dropdown.show").removeClass("show");
    $("div.menu-group > ul.nav.navbar-nav > li.nav-item > a").attr("aria-hidden", "false");
};

module.exports = function () {
    menuDropdown();

    // Disable iNoBounce to fix issue with scroll on iOS mobile
    iNoBounce.disable();

    var isDesktop = function (element) {
        return $(element).parents(".menu-toggleable-left").css("position") !== "fixed";
    };

    var headerBannerStatus = window.sessionStorage.getItem("hide_header_banner");
    $(".header-banner .close").on("click", function () {
        $(".header-banner").addClass("d-none");
        window.sessionStorage.setItem("hide_header_banner", "1");
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $(".header-banner").removeClass("d-none");
    }

    keyboardAccessibility(".main-menu .nav-link, .main-menu .dropdown-link",
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass("nav-item")) { // top level
                    $(".navbar-nav .show").removeClass("show")
                        .children(".dropdown-menu")
                        .removeClass("show");
                    menuItem.addClass("show").children(".dropdown-menu").addClass("show");
                    menuItem.find("ul > li > a")
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                    if (menuItem.next().length <= 0) { // if this is the last menuItem
                        menuItem.parent().parent().find("li > a") // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass("nav-item")) { // top level
                    menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                    $(this).attr("aria-expanded", "false");
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass("dropdown")) {
                    menuItem.addClass("show").children(".dropdown-menu").addClass("show");
                    $(this).attr("aria-expanded", "true");
                    menuItem.find("ul > li > a")
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass("nav-item")) { // top level
                    menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass("show")
                        .children(".nav-link")
                        .attr("aria-expanded", "false");
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass("nav-item")) { // top level
                    menuItem.removeClass("show").children(".dropdown-menu").removeClass("show");
                    $(this).attr("aria-expanded", "false");
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest(".show").removeClass("show")
                        .closest("li.show").removeClass("show")
                        .children()
                        .first()
                        .focus()
                        .attr("aria-expanded", "false");
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass("show")
                    ? menuItem
                    : menuItem.closest("li.show");
                parentMenu.children(".show").removeClass("show");
                parentMenu.removeClass("show").children(".nav-link")
                    .attr("aria-expanded", "false");
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $(".dropdown:not(.disabled) .nav-link")
        .on("click", function (e) {
            if (!isDesktop(this) && typeof $(this).attr("data-toggle") !== "undefined") {
                $(".modal-background").show();
                $(this).parent().addClass("show");
                $(this).siblings(".dropdown-menu").attr("aria-hidden", "false");
                $(this).attr("aria-expanded", "true");
                $("div.menu-group > ul.nav.navbar-nav > li.nav-item > a").attr("aria-hidden", "true");
                e.preventDefault();
            }
        })
        .on("mouseenter", function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $(".navbar-nav > li").each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find(".show").each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass("show")) {
                            $(this).removeClass("show");
                            $(this).children("ul.dropdown-menu").removeClass("show");
                            $(this).children(".nav-link").attr("aria-expanded", "false");
                        }
                    }
                });
                $(".modal-background").show();
                $(".header-container").css({
                    position: "relative"
                });
                $(this).parent().addClass("show");
                $(this).siblings(".dropdown-menu").addClass("show");
                $(this).attr("aria-expanded", "true");

                var levelTwoSubcategories = $(this).parent().find(".nav-item.level-2");

                if (levelTwoSubcategories.length > 0) {
                    levelTwoSubcategories.first().addClass("show");
                    levelTwoSubcategories.first().find(".dropdown-menu").addClass("show");
                    levelTwoSubcategories.first().find(".nav-link").attr("aria-expanded", "true");
                }
            }
        })
        .parent()
        .on("mouseleave", function () {
            if (isDesktop(this)) {
                $(this).removeClass("show");
                $(this).find(".dropdown-menu").removeClass("show");
                $(this).find(".nav-item.show").removeClass("show");
                $(this).children(".nav-link").attr("aria-expanded", "false");

                if ($.find(".nav-item.show").length === 0 && $.find(".dropdown-menu.show").length === 0) {
                    $(".modal-background").hide();
                    $(".header-container").css({
                        position: "static"
                    });
                }
            }
        });

    $(".navbar-nav").on("click", ".back", function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $(document).mouseup(function (e) {
        var container = $(".navbar-nav");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            e.preventDefault();
            $("body").removeClass("mobile-nav-expanded");
            iNoBounce.disable();
            $(".navbar-nav").find(".top-category").detach();
            $(".navbar-nav").find(".link-text").detach();
            $(".navbar-nav").find(".show").removeClass("show");
            $(".menu-toggleable-left").removeClass("in");
            $(".main-menu").siblings().attr("aria-hidden", "false");
            $("header").siblings().attr("aria-hidden", "false");
            $(".modal-background").hide();
        }
    });

    $(".navbar-toggler").click(function (e) {
        e.preventDefault();
        $("body").addClass("mobile-nav-expanded");
        iNoBounce.disable();
        $(".main-menu").toggleClass("in");
        $(".modal-background").show();
        $(".main-menu").attr("aria-hidden", "false");
        $(".main-menu").siblings().attr("aria-hidden", "true");
        $("header").siblings().attr("aria-hidden", "true");

        $(".main-menu .nav.navbar-nav .nav-link").first().focus();
    });

    keyboardAccessibility(".navbar-header .user",
        {
            40: function ($popover) { // down
                if ($popover.children("a").first().is(":focus")) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children("a").first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children("a").first().is(":focus")) {
                    $(this).focus();
                    $popover.removeClass("show");
                } else {
                    $popover.children("a").first().focus();
                }
            },
            27: function () { // escape
                $(".navbar-header .user .popover").removeClass("show");
                $(".user").attr("aria-expanded", "false");
            },
            9: function () { // tab
                $(".navbar-header .user .popover").removeClass("show");
                $(".user").attr("aria-expanded", "false");
            }
        },
        function () {
            var $popover = $(".user .popover li.nav-item");
            return $popover;
        }
    );

    $(".navbar-header .user").on("mouseenter focusin", function () {
        if ($(".navbar-header .user .popover").length > 0) {
            $(".navbar-header .user .popover").addClass("show");
            $(".user").attr("aria-expanded", "true");
        }
    });

    $(".navbar-header .user").on("mouseleave", function () {
        $(".navbar-header .user .popover").removeClass("show");
        $(".user").attr("aria-expanded", "false");
    });
    $("body").on("click", "#myaccount", function () {
        event.preventDefault();
    });
    $(".navbar-nav").on("click", ".has-submenu", function () {
        $(this).toggleClass("open-submenu");
    });
    $(".toggle-acc-menu i").on("click", function () {
        if ($("#secondarycontent .acc-navigation").length > 0) {
            var nav = $(".acc-navigation");
            nav.insertAfter(".acc-card-header");
        }
        $(this).closest(".row").toggleClass("show-left-nav");
    });
};

$(window).on("scroll", function () {
    var bannerHeight =  $(window).height();
    var yScroll = $(window).scrollTop();
    if (yScroll > bannerHeight) {
        $("#boxThis").addClass("box").css("position", "fixed");
    } else {
        $("#boxThis").removeClass("box").removeAttr("style");
    }
});

// $(window).on("load", function () {
//     $(".five9-chat").css({"display": "none"});
// });


var chatLink = $(".five9-chat");
chatLink.on("click", function () {
    $(".helpButtonEnabled ").click();
});
